import React from 'react';

import { StyledBriefingItemContainer, StyledIcon, StyledTextContainer } from './styled';

const QuizBriefing: React.FC = () => {
  return (
      <div className="rounded-lg shadow-normal border border-neutral-200 border-opacity-20 lg:py-4 lg:px-0 px-4">
        <div className="flex grow flex-col lg:flex-row">
          <StyledBriefingItemContainer>
            <div>
              <StyledIcon icon="icon-two-options"/>
            </div>
            <div className="flex lg:flex-col gap-2 items-start flex-wrap">
              <StyledTextContainer>Choose 2 descriptions:</StyledTextContainer>
            <div className='flex lg:flex-col flex-row gap-2'>
              <StyledTextContainer>1&#41; Your best representation and</StyledTextContainer>
              <StyledTextContainer>2&#41; A closely aligned one</StyledTextContainer>
            </div>
            </div>
          </StyledBriefingItemContainer>
          <StyledBriefingItemContainer>
            <div>
              <StyledIcon icon="icon-est-time" />
            </div>
            <StyledTextContainer className="">
              Takes about 3 minutes
            </StyledTextContainer>
          </StyledBriefingItemContainer>
          <StyledBriefingItemContainer>
            <div>
              <StyledIcon icon="icon icon-all-qns" />
            </div>
            <StyledTextContainer>
              You must answer all questions
            </StyledTextContainer>
          </StyledBriefingItemContainer>
        </div>
      </div>
  );
};

export default QuizBriefing;
